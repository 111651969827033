<template>
    <div id="weather-detail">
        <div>
            <p style="margin-left:36px;">请选择统计时段：
                <el-date-picker v-model="timePicker" type="daterange" align="right" unlink-panels range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" style="width: 33%;">
                </el-date-picker>
                <el-button type="primary" style="margin-left:20px;" @click="timeChanged">筛选</el-button>
                <iframe width="320" scrolling="no" height="24" style="position:relative;top:7px;left:28px;"
                    frameborder="0" allowtransparency="true"
                    src="https://i.tianqi.com?c=code&id=11&bdc=%23&icon=2&site=14"></iframe>
                <el-tooltip placement="left" effect="light">
                    <el-button circle type="info" icon="el-icon-message" style="float:right;margin-right:36px;">
                    </el-button>
                    <div slot="content">
                        <b>·</b>&nbsp;数据均以每日十二时为准
                        <br />
                        <b>·</b>&nbsp;数据值为-1时，则该日该时气象站并未上报该类数据
                    </div>
                </el-tooltip>
            </p>
        </div>
        <el-card style="width:94%;margin-left:3%;">
            <div id="analysis-div" style="width:100%;height:400px;"></div>
        </el-card>
    </div>
</template>

<script>
    import * as echarts from 'echarts';

    var myChart;
    export default {
        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '当天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                timePicker: [],
                queryTime: {
                    start: "",
                    end: "",
                },
            }
        },
        created() {

        },
        mounted() {
            this.getCurrentWeather();
        },
        methods: {
            getCurrentWeather() {
                var date = new Date();
                date = date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                this.timePicker.push(new Date(date));
                this.timePicker.push(new Date());
                this.timeChanged();
            },
            timeChanged() {
                if (this.timePicker.length != 2) {
                    this.$message.warning("请确定统计时段！");
                    return;
                }
                this.queryTime.start = timeStampToFormatedDateStr(this.timePicker[0].getTime());
                this.queryTime.end = timeStampToFormatedDateStr(this.timePicker[1].getTime());
                setTimeout(() => {
                    if (myChart != null && myChart != "" && myChart != undefined)
                        myChart.dispose();
                    var chartDom = document.getElementById('analysis-div');
                    myChart = echarts.init(chartDom);
                    let option;
                    let legendData, xAxisData, series = new Array();
                    this.$webAxios.post("/jsh-api/census/weather2", this.queryTime).then(res => {
                        res = res.data;
                        // legendData = ["空气温度","空气湿度","土壤温度","土壤水分","大气压力","实时风速","CO2浓度","PM2.5"];
                        legendData = ["空气温度", "空气湿度", "土壤水分", "大气压力", "实时风速", "CO2浓度", "PM2.5"];
                        xAxisData = res.time;
                        series = [
                            {
                                name: '空气温度',
                                type: 'line',
                                data: res.value1
                            },
                            {
                                name: '空气湿度',
                                type: 'line',
                                data: res.value2
                            },
                            // {
                            //     name: '土壤温度',
                            //     type: 'line',
                            //     data: res.value3
                            // },
                            {
                                name: '土壤水分',
                                type: 'line',
                                data: res.value4
                            },
                            {
                                name: '大气压力',
                                type: 'line',
                                data: res.value5
                            },
                            {
                                name: '实时风速',
                                type: 'line',
                                data: res.value6
                            },
                            {
                                name: 'CO2浓度',
                                type: 'line',
                                data: res.value7
                            },
                            {
                                name: 'PM2.5',
                                type: 'line',
                                data: res.value8
                            },
                        ];
                        option = {
                            tooltip: {
                                trigger: 'axis',
                                formatter: (params) => {
                                    var str = "";
                                    str += params[0].marker + "" + params[0].seriesName + "：" + params[0].data + " " + "℃" + "<br />";
                                    str += params[1].marker + "" + params[1].seriesName + "：" + params[1].data + " " + "RH" + "<br />";
                                    // str += params[2].marker + "" + params[2].seriesName + "：" + params[2].data + " " + "℃" + "<br />";
                                    // str += params[3].marker + "" + params[3].seriesName + "：" + params[3].data + " " + "g/cm3" + "<br />";
                                    // str += params[4].marker + "" + params[4].seriesName + "：" + params[4].data + " " + "Pa" + "<br />";
                                    // str += params[5].marker + "" + params[5].seriesName + "：" + params[5].data + " " + "m/s" + "<br />";
                                    // str += params[6].marker + "" + params[6].seriesName + "：" + params[6].data + " " + "ppm" + "<br />";
                                    // str += params[7].marker + "" + params[7].seriesName + "：" + params[7].data + " " + "μm" + "<br />";
                                    str += params[2].marker + "" + params[2].seriesName + "：" + params[2].data + " " + "g/cm3" + "<br />";
                                    str += params[3].marker + "" + params[3].seriesName + "：" + params[3].data + " " + "Pa" + "<br />";
                                    str += params[4].marker + "" + params[4].seriesName + "：" + params[4].data + " " + "m/s" + "<br />";
                                    str += params[5].marker + "" + params[5].seriesName + "：" + params[5].data + " " + "ppm" + "<br />";
                                    str += params[6].marker + "" + params[6].seriesName + "：" + params[6].data + " " + "μm" + "<br />";
                                    return str;
                                }
                            },
                            legend: {
                                data: legendData
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            toolbox: {
                                feature: {
                                    saveAsImage: {}
                                }
                            },
                            xAxis: {
                                type: 'category',
                                boundaryGap: false,
                                data: xAxisData
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: series,
                            dataZoom: [{
                                type: 'inside',
                                show: true,
                                startValue: 0,
                                endValue: 5,
                                height: '15',
                                bottom: '3%',
                                zoomOnMouseWheel: true
                            }],
                        };
                        option && myChart.setOption(option, true);
                    })
                }, 10);
            },
        },
    }

    function timeStampToFormatedDateStr(timeStamp) {
        var time = new Date(timeStamp);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
    }

    function add0(m) {
        return m < 10 ? '0' + m : m;
    }
</script>

<style>
    #weather-detail .time {
        font-size: 13px;
        color: #999;
    }

    #weather-detail .bottom {
        margin-top: 13px;
        line-height: 12px;
    }

    #weather-detail .button {
        padding: 0;
        float: right;
    }

    #weather-detail .image {
        width: 100%;
        display: block;
    }

    #weather-detail .clearfix:before,
    #weather-detail .clearfix:after {
        display: table;
        content: "";
    }

    #weather-detail .clearfix:after {
        clear: both
    }

    #weather-detail th,
    #weather-detail td {
        text-align: center !important;
    }

    #weather-detail .el-range-separator {
        width: 10% !important;
    }
</style>